import React, { useRef } from "react"
import PropTypes from "prop-types"

import MLUTextField from "components/ui/mlutextfield/mlutextfield.jsx"
import { LUCheckbox } from "webclient-ui/components"
import MLUButton, {
  MLUButtonSize,
  MLUButtonType,
} from "components/ui/mlubutton/mlubutton"
import { LUSelect } from "components/select/select"

import { ThemeProvider } from "@mui/material"
import LUTheme from "components/ui/themes/lutheme"

import { t } from "entities/doorbell/doorbell-form.i18n"
import css from "entities/doorbell/doorbell-form.module.scss"
import { trackClickTutorialLink } from "entities/doorbell/doorbell-form.tracking"

export const DoorbellFormSection = ({
  ticketOptions,
  tutorialLinks,
  isLoading,
  ticketMessage,
  selectedTicketType,
  isScreenshotAttached,
  hasAutoFocus,
  onTicketTypeSelect,
  onTicketMessageChange,
  onScreenshotToggle,
  filesSelectedForUpload,
  setFilesSelectedForUpload,
  isUserAttemptedToUploadTooLargeFile,
  setIsUserAttemptedToUploadTooLargeFile,
}) => {
  const fileSelectionDialog = useRef()

  const openFileSelectionDialog = () => fileSelectionDialog?.current?.click()

  const handleFileSelection = () => {
    /*
    First we got to check if none of the files exceed size threshold of 5MiB
    Since, this is the cap enforced by Doorbell for every uploaded file
    */
    const newlySelectedFiles = Array.from(fileSelectionDialog.current.files)

    const fileThatIsTooLarge = newlySelectedFiles.find(
      (file) => file.size / 1024 / 1024 > 5,
    )

    // If so, we mark it in the UI and return
    if (fileThatIsTooLarge) {
      setIsUserAttemptedToUploadTooLargeFile(true)

      return
    }

    setIsUserAttemptedToUploadTooLargeFile(false)

    // We also clean duplicates
    const newlySelectedFilesWithoutDuplicates = newlySelectedFiles.filter(
      (newFile) =>
        !filesSelectedForUpload
          .map((alreadySelectedFile) => alreadySelectedFile.name)
          .includes(newFile.name),
    )

    const cleanPoolOfSelectedFiles = [
      ...filesSelectedForUpload,
      ...newlySelectedFilesWithoutDuplicates,
    ]

    setFilesSelectedForUpload(cleanPoolOfSelectedFiles)
  }

  const handleFileRemoval = (fileToRemove) => () => {
    setFilesSelectedForUpload(
      filesSelectedForUpload.filter(
        (selectedFile) => selectedFile.name !== fileToRemove.name,
      ),
    )
  }

  return (
    <ThemeProvider theme={LUTheme}>
      <div
        className={css["doorbell_form_section"]}
        data-testid={"doorbell_form_section"}>
        <div className={css["doorbell_form_section__tutorial_links"]}>
          <h3>{t("doorbell_documentation_callout")}</h3>
          {tutorialLinks.map((link) => (
            <a
              key={link.urlKey}
              href={t(link.urlKey)}
              target="_blank"
              onClick={() =>
                trackClickTutorialLink("form view", t(link.titleKey))
              }
              rel="noopener noreferrer">
              <i className="fa fa-external-link-alt" /> {t(link.titleKey)}
            </a>
          ))}
        </div>
        <div className={css["doorbell_form_section__form"]}>
          <ol>
            <li>
              <label className={css["li-title"]}>
                {t("doorbell_dialog_subject")}
              </label>
              <LUSelect
                disabled={isLoading}
                color="yellow"
                isCreatable={false}
                openOnFocus={true}
                clearable={false}
                resetValue={null}
                placeholder={t("doorbell_subject_placeholder")}
                value={selectedTicketType}
                options={ticketOptions.map((option) => ({
                  label: t(`doorbell_ticket_option_${option.value}`),
                  value: option.value,
                }))}
                onChange={onTicketTypeSelect}
              />
            </li>
            <li>
              <label className={css["li-title"]}>
                {t("doorbell_dialog_question")}
              </label>
              <div>
                {selectedTicketType && selectedTicketType.value !== "other" ? (
                  <div className={css["doorbell_form_section__helper_text"]}>
                    <i className="fa fa-info-circle" />
                    <p>
                      {t(`doorbell_helper_text_${selectedTicketType.value}`)}
                    </p>
                  </div>
                ) : null}
              </div>
              <MLUTextField
                className={css["doorbell_form_section__form__input"]}
                placeholder={t("doorbell_ticket_message_placeholder")}
                onChange={onTicketMessageChange}
                value={ticketMessage}
                multiline={true}
                fullWidth={true}
                inputProps={{ maxLength: 1000, minRows: 3 }}
                autoFocus={hasAutoFocus}
                disabled={isLoading}
              />
            </li>
            <li>
              <label
                className={
                  css["li-title"]
                }>{`${t("doorbell_add_attachment")} (${t("optional")})`}</label>
              <input
                ref={fileSelectionDialog}
                data-testid={"file-selection-dialog"}
                type="file"
                className={css["doorbell_form_section__file_input_field"]}
                onChange={handleFileSelection}
              />
              <div className={css["doorbell_form_section__action_section"]}>
                <MLUButton
                  label={t("doorbell_add_attachment")}
                  color="lightGray"
                  size={MLUButtonSize.SMALL}
                  icon={<i className="fa fa-plus-circle" />}
                  onClick={openFileSelectionDialog}
                />
                <div
                  className={css["doorbell_form_section__file_size_threshold"]}>
                  {t("doorbell_file_size_threshold")}
                </div>
              </div>
              {isUserAttemptedToUploadTooLargeFile && (
                <div
                  className={
                    css["doorbell_form_section__file_is_too_large_warning"]
                  }>
                  <i className="fa fa-info-circle" />
                  <p>{t("doorbell_file_too_large_warning")}</p>
                </div>
              )}
              <div className={css["doorbell_include_screenshot_container"]}>
                <LUCheckbox
                  colorTheme="yellow"
                  label={t("doorbell_include_screenshot")}
                  isDisabled={isLoading}
                  isChecked={isScreenshotAttached}
                  onChange={onScreenshotToggle}
                />
                <div>{t("doorbell_include_screenshot_description")}</div>
              </div>
            </li>
          </ol>
          <div
            className={
              filesSelectedForUpload.length > 0
                ? css[
                    "doorbell_form_section__files_selected_for_upload_container"
                  ]
                : null
            }>
            {filesSelectedForUpload.map((file, index) => {
              return (
                <div key={`file-selected-for-upload-${file.name}`}>
                  <div
                    className={
                      css[
                        "doorbell_form_section__single_file_selected_for_upload_container"
                      ]
                    }>
                    <i className="fa fa-paperclip" />
                    <div>{file.name}</div>
                    <MLUButton
                      color="gray"
                      size={MLUButtonSize.SMALL}
                      type={MLUButtonType.TEXT}
                      label={t("doorbell_delete_attachment")}
                      icon={<i className="fas fa-trash-alt" />}
                      onClick={handleFileRemoval(file)}
                    />
                  </div>
                  <div
                    className={
                      index !== filesSelectedForUpload.length - 1
                        ? css["doorbell_form_section__file_delimeter"]
                        : null
                    }
                  />
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </ThemeProvider>
  )
}

DoorbellFormSection.prototypes = {
  selectedTicketType: PropTypes.object.isRequired,
  filesSelectedForUpload: PropTypes.arrayOf(PropTypes.string),
  setFilesSelectedForUpload: PropTypes.func,
  mixpanelTrackingInput: PropTypes.object,
  setMixpanelTrackingInput: PropTypes.func,
}
