import React from "react"
import PropTypes from "prop-types"
import cx from "classnames"

import * as RouterUtils from "lib/core/router.utils"
import * as SessionAPI from "lib/core/session.api"
import { history } from "store"

import { LUInput } from "webclient-ui/components"
import MLUButton from "components/ui/mlubutton/mlubutton"

import { t } from "entities/account__invite.page/account__invite.i18n"
import css from "entities/account__invite.page/account__invite.module.scss"

export class AccountInviteView extends React.Component {
  static propTypes = {
    errors: PropTypes.object,
    inviteKey: PropTypes.string.isRequired,
    inviteID: PropTypes.number.isRequired,
    inviteEmail: PropTypes.string.isRequired,
    isInviteAccepted: PropTypes.bool.isRequired,
  }

  static defaultProps = {
    errors: {},
  }

  state = {
    firstName: "",
    lastName: "",
    password: "",
    passwordConfirmation: "",
    enableSubmit: true,
  }

  tryToLoginOrRedirectToLogin = (props) => {
    if (props.isInviteAccepted) {
      const { inviteEmail, handleLogin } = props
      const { password } = this.state

      if (password) {
        handleLogin(
          {
            username: inviteEmail,
            password,
          },
          {},
        )
      } else {
        this.redirectToLogin(props.inviteEmail)
      }
    }
  }

  componentDidMount = () => {
    this.tryToLoginOrRedirectToLogin(this.props)
  }

  componentDidUpdate = () => {
    this.tryToLoginOrRedirectToLogin(this.props)
  }

  render() {
    const { errors, inviteEmail } = this.props
    const { firstName, lastName, password, passwordConfirmation } = this.state

    return (
      <div
        className={css["invite-wrapper"]}
        data-testid={"account_invite__view"}>
        <div className={cx("animated", "fadeIn", css.invite)}>
          <div className={css.invite__logo} />
          <div className={css.invite__form}>
            <h1 className={css.invite__title}>{t("invite__register")}</h1>
            <LUInput
              className={css["form-item"]}
              value={inviteEmail}
              hasBar={false}
              isDisabled={true}
              disableBrowserAutoComplete={true}
            />
            <LUInput
              className={css["form-item"]}
              placeholder={t("invite__first-name-placeholder")}
              value={firstName}
              error={errors.firstName}
              hasAutoFocus={true}
              hasBar={false}
              onChange={this.update("firstName")}
              disableBrowserAutoComplete={true}
            />
            <LUInput
              className={css["form-item"]}
              placeholder={t("invite__last-name-placeholder")}
              value={lastName}
              error={errors.lastName}
              hasBar={false}
              onChange={this.update("lastName")}
              disableBrowserAutoComplete={true}
            />
            <LUInput
              className={css["form-item"]}
              type="password"
              placeholder={t("invite__password-placeholder")}
              value={password}
              error={errors.password}
              hasBar={false}
              onChange={this.update("password")}
              disableBrowserAutoComplete={true}
            />
            <LUInput
              className={css["form-item"]}
              type="password"
              placeholder={t("invite__password-confirm-placeholder")}
              value={passwordConfirmation}
              error={errors.passwordConfirmation}
              hasBar={false}
              onChange={this.update("passwordConfirmation")}
              disableBrowserAutoComplete={true}
            />

            <div className={css["invite__submit-wrapper"]}>
              <MLUButton
                icon={<i className="fa fa-plus" />}
                label={t("invite__register")}
                onClick={this.handleSubmit}
                disabled={!this.state.enableSubmit}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }

  handleSubmit = () => {
    const { inviteEmail, inviteKey, handleInvitationAccept } = this.props
    const { firstName, lastName, password, passwordConfirmation } = this.state

    this.setState({ enableSubmit: false })
    handleInvitationAccept(inviteKey, {
      email: inviteEmail,
      firstName,
      lastName,
      password,
      passwordConfirmation,
    }).catch(() => {
      // If accepting the invitation fails, allow submitting the form again.
      // However, if it succeeds, we redirect to the site anyway, so there's no
      // need to reenable submitting the form.
      this.setState({ enableSubmit: true })
    })
  }

  update = (field) => (value) => {
    this.setState({ [field]: value })
  }

  redirectToLogin(email) {
    if (email) {
      SessionAPI.saveUsername(email)
    }

    setTimeout(() => history.push(RouterUtils.get("account__login")), 1)
  }
}
