import { translateWith } from "lib/core/i18n/i18n.utils"
import {
  PUPIL_APPROACHES,
  CITO_SCORE_FORMATS,
  METHOD_SCORE_FORMATS,
  UNDEFINED_PUPIL_APPROACH,
  METHOD_SCORES_SHORT,
  ALL_OPTION_VALUE,
  SHORT_I18N,
  SINGULAR_I18N,
  TEST_PROVIDERS,
} from "lib/leeruniek/constants"

export const languagePack = {
  text: {
    nl: "Tekst",
    en: "Text",
  },
  apply: { nl: "Toepassen", en: "Apply" },
  next: {
    nl: "Volgende stap",
    en: "Next",
  },
  prev: {
    nl: "Vorige stap",
    en: "Previous",
  },
  name: {
    nl: "Naam",
    en: "Name",
  },
  select: {
    nl: "Selecteer",
    en: "Select",
  },
  create: {
    nl: "Nieuw",
    en: "New",
  },
  print: {
    nl: "Afdrukken / PDF",
    en: "Print / PDF",
  },
  creating: {
    nl: "Creating",
    en: "Creating",
  },
  upload: {
    nl: "Uploaden",
    en: "Upload",
  },
  edit: {
    nl: "Bewerken",
    en: "Edit",
  },
  update: {
    nl: "Bijwerken",
    en: "Update",
  },
  archive: {
    nl: "Archiveren",
    en: "Archive",
  },
  unarchive: {
    nl: "Terugplaatsen",
    en: "Unarchive",
  },
  undo: {
    nl: "Ongedaan maken",
    en: "Undo",
  },
  "undo-archive": {
    nl: "Archiveren ongedaan maken",
    en: "Undo archive",
  },
  save: {
    nl: "Opslaan",
    en: "Save",
  },
  confirm: {
    nl: "Bevestigen",
    en: "Confirm",
  },
  saving: {
    nl: "Wordt opgeslagen",
    en: "Saving",
  },
  saved: {
    nl: "Opgeslagen",
    en: "Saved",
  },
  close: {
    nl: "Afsluiten",
    en: "Close",
  },
  cancel: {
    nl: "Annuleren",
    en: "Cancel",
  },
  delete: {
    nl: "Verwijderen",
    en: "Delete",
  },
  deleting: {
    nl: "Wordt verwijderd",
    en: "Deleting",
  },
  submit: {
    nl: "Verstuur",
    en: "Submit",
  },
  submiting: {
    nl: "Wordt verstuurd",
    en: "Submitting",
  },
  unsaved: {
    nl: "Niet opgeslagen",
    en: "Unsaved",
  },
  show: {
    nl: "Verberg",
    en: "Show",
  },
  hide: {
    nl: "Toon",
    en: "Hide",
  },
  display: {
    nl: "Weergave",
    en: "Display",
  },
  "read-more": {
    nl: "Lees meer",
    en: "Show more",
  },
  "read-less": {
    nl: "Lees minder",
    en: "Show less",
  },
  "show-more": {
    nl: "Meer weergeven",
    en: "Show more",
  },
  "show-less": {
    nl: "Minder weergeven",
    en: "Show less",
  },
  "type-to-search": {
    nl: "Typ om te zoeken",
    en: "Type to search",
  },
  optional: {
    nl: "Optioneel",
    en: "Optional",
  },
  active: {
    nl: "Actief",
    en: "Active",
  },
  "not-active": {
    nl: "Inactief",
    en: "Not active",
  },
  all: {
    nl: "Alle",
    en: "All",
  },
  none: {
    nl: "Geen",
    en: "None",
  },
  lower: {
    nl: "Lager",
    en: "Lower",
  },
  higher: {
    nl: "Hoger",
    en: "Higher",
  },
  from: {
    nl: "Vanaf",
    en: "From",
  },
  until: {
    nl: "Tot",
    en: "Until",
  },
  not_found: {
    nl: "Niet gevonden",
    en: "Not found",
  },
  support: {
    nl: "Support",
    en: "Support",
  },
  handover: {
    nl: "Overdracht",
    en: "Handover",
  },
  group_discussion: {
    nl: "Groepsbespreking",
    en: "Group discussion",
  },
  two_factor_authentication: {
    nl: "Tweestapsverificatie",
    en: "Two-factor authentication",
  },
  birthdate: {
    en: "Birthdate",
    nl: "Geboortedatum",
  },
  age: { en: "Age", nl: "Leeftijd" },
  didacticAge: { en: "Didactic age", nl: "Didactische Leeftijd" },
  classProgression: { en: "Class progression", nl: "Schoolloopbaan" },
  ["factor_block_title"]: {
    nl: "Kindkenmerken",
    en: "Annotations",
  },
  ["factor_block_title--archive"]: {
    nl: "Kindkenmerken archief",
    en: "Annotations archive",
  },
  ["social_emotional_observations_block_title"]: {
    nl: "Sociaal emotioneel - gemarkeerde observaties",
    en: "Social emotional - marked observations ",
  },
  ["social_emotional_notes_block_title"]: {
    nl: "Sociaal emotioneel - aantekeningen",
    en: "Social emotional - notes",
  },
  ["evaluations"]: {
    nl: "Evaluaties",
    en: "Evaluations",
  },
  ["method_evaluations"]: {
    nl: "Methode evaluaties",
    en: "Method evaluations",
  },
  test: {
    nl: "Toets",
    en: "Test",
  },
  "test-moment": {
    nl: "Toetsmoment",
    en: "Test moment",
  },
  "adaptive-test": {
    nl: "Adaptieve toets",
    en: "Adaptive test",
  },
  "written-by": {
    nl: "Geschreven door",
    en: "Written by",
  },
  max: { nl: "Max", en: "Max" },
  test_goals: {
    nl: "Toetsdoelen",
    en: "Test goals",
  },
  "modified-by": {
    nl: "Gewijzigd door",
    en: "Modified by",
  },
  "field-created": {
    nl: "Aangemaakt",
    en: "Created",
  },
  "field-created-by": {
    nl: "Aangemaakt door",
    en: "Created by",
  },
  "field-archived": {
    nl: "Gearchiveerd",
    en: "Archived",
  },
  "field-date": {
    nl: "Datum",
    en: "Date",
  },
  warning: {
    nl: "Let op",
    en: "Warning",
  },
  "something-went-wrong": {
    nl: "Er is iets fout gegaan",
    en: "Something went wrong",
  },
  "then-in": {
    nl: "Zat in",
    en: "Then in",
  },
  "now-in": {
    nl: "Nu in",
    en: "Now in",
  },
  error_boundary: {
    nl: "Er is een probleem opgetreden. Ververs de pagina of neem contact met ons op via de feedback-knop. Wij helpen je graag weer verder!",
    en: "A problem has occured. Refresh the page or contact us via the feedback button. We are happy to help you further!",
  },
  "form-input__error-required": {
    nl: "Veld is verplicht",
    en: "Field is required",
  },
  "under-construction": {
    nl: "Nog niet beschikbaar",
    en: "Under construction",
  },
  "click-here": {
    nl: "Klik hier",
    en: "Click here",
  },
  ["norm"]: {
    nl: "Norm",
    en: "Norm",
  },
  ["score"]: {
    nl: "Score",
    en: "Score",
  },
  ["stimulating_factors"]: {
    nl: "Stimulerende factoren",
    en: "Stimulating factors",
  },
  ["influencing_factors"]: {
    nl: "Belemmerende factoren",
    en: "Influencing factors",
  },
  ["educational_needs"]: {
    nl: "Onderwijsbehoeften",
    en: "Educational needs",
  },
  ["results"]: {
    nl: "Resultaten",
    en: "Results",
  },
  ["semi_annual_tests"]: {
    nl: "Halfjaarlijkse toetsen",
    en: "Semi-annual tests",
  },
  ["latest_results"]: {
    nl: "Laatste resultaten",
    en: "Latest results",
  },
  ["current-approach"]: {
    nl: "Huidige instructiegroep",
    en: "Current instruction group",
  },
  [`${PUPIL_APPROACHES.ENRICHED}-approach-name`]: {
    nl: "Verrijkt",
    en: "Enriched",
  },
  [`${PUPIL_APPROACHES.BASIC}-approach-name`]: {
    nl: "Basis",
    en: "Basic",
  },
  [`${PUPIL_APPROACHES.INTENSIVE}-approach-name`]: {
    nl: "Intensief",
    en: "Intensive",
  },
  [`${UNDEFINED_PUPIL_APPROACH}-approach-name`]: {
    nl: "Niet ingedeeld",
    en: "Unassigned",
  },
  [PUPIL_APPROACHES.BASIC]: {
    nl: "Basis",
    en: "Basic",
  },
  [PUPIL_APPROACHES.ENRICHED]: {
    nl: "Verrijkte",
    en: "Enriched",
  },
  [PUPIL_APPROACHES.INTENSIVE]: {
    nl: "Intensieve",
    en: "Intensive",
  },
  evaluation: {
    nl: "Evaluatie",
    en: "Evaluation",
  },
  forward_looking_note: {
    nl: "Aanpak n.a.v.",
    en: "Planning note",
  },
  note: {
    nl: "Aantekening",
    en: "Note",
  },
  notes: {
    nl: "Aantekeningen",
    en: "Notes",
  },
  grade: {
    nl: "Leerjaar",
    en: "Grade",
  },
  all_grades: {
    nl: "Alle leerjaren",
    en: "All grades",
  },
  group: {
    nl: "Groep",
    en: "Group",
  },
  all_groups: {
    nl: "Alle groepen",
    en: "All groups",
  },
  individual: {
    nl: "Individueel",
    en: "Individual",
  },
  ["methods_results"]: {
    nl: "Methoderesultaten",
    en: "Method Results",
  },
  [`pupil-approach-${PUPIL_APPROACHES.ENRICHED}-group`]: {
    nl: "Verrijkte groep",
    en: "Enriched group",
  },
  [`pupil-approach-${PUPIL_APPROACHES.BASIC}-group`]: {
    nl: "Basisgroep",
    en: "Basic group",
  },
  [`pupil-approach-${PUPIL_APPROACHES.INTENSIVE}-group`]: {
    nl: "Intensieve groep",
    en: "Intensive group",
  },
  [`pupil-approach-${ALL_OPTION_VALUE}-group`]: {
    nl: "Gehele groep",
    en: "Whole group",
  },
  ["pupil-approach-whole-group"]: {
    nl: "Hele groep",
    en: "Whole group",
  },
  ["pupil-approach-group"]: {
    nl: "{{approach}} instructiegroep",
    en: "{{approach}} instruction group",
  },
  [`level_${METHOD_SCORES_SHORT.INSUFFICIENT}`]: {
    nl: "Onvoldoende",
    en: "Insufficient",
  },
  [`level_${METHOD_SCORES_SHORT.MODERATE}`]: {
    nl: "Matig",
    en: "Moderate",
  },
  [`level_${METHOD_SCORES_SHORT.SUFFICIENT}`]: {
    nl: "Voldoende",
    en: "Sufficient",
  },
  [`level_${METHOD_SCORES_SHORT.GOOD}`]: { nl: "Goed", en: "Good" },
  subject: {
    nl: "Vak",
    en: "Subject",
  },
  [CITO_SCORE_FORMATS.TEST_CODE_EXTRA]: {
    nl: "Toetsonderdeel",
    en: "Test part",
  },
  [`${CITO_SCORE_FORMATS.LEVEL_VALUE}${SINGULAR_I18N}`]: {
    nl: "Niveauwaarde",
    en: "Level value",
  },
  [`${CITO_SCORE_FORMATS.ABILITY_SCORE}${SINGULAR_I18N}`]: {
    nl: "Vaardigheidsscore",
    en: "Ability score",
  },
  [`${CITO_SCORE_FORMATS.PERCENTILE_SCORE}${SINGULAR_I18N}`]: {
    nl: "Percentielscore",
    en: "Percentile score",
  },
  [`${CITO_SCORE_FORMATS.ABILITY_SCORE}${SINGULAR_I18N}${TEST_PROVIDERS.IEP}`]:
    {
      nl: "Ontwikkelscore",
      en: "Development score",
    },
  [CITO_SCORE_FORMATS.LEVEL_VALUE]: {
    nl: "Niveauwaarden",
    en: "Level values",
  },
  [CITO_SCORE_FORMATS.ABILITY_SCORE]: {
    nl: "Vaardigheidsscores",
    en: "Ability scores",
  },
  [CITO_SCORE_FORMATS.PERCENTILE_SCORE]: {
    nl: "Percentielscores",
    en: "Percentile scores",
  },
  [CITO_SCORE_FORMATS.ALPHABETICAL]: {
    nl: "A t/m E",
    en: "A to E",
  },
  [CITO_SCORE_FORMATS.ROMAN_NUMERAL]: {
    nl: "I t/m V",
    en: "I to V",
  },
  [CITO_SCORE_FORMATS.FUNCTIONAL_LEVEL]: {
    nl: "Functioneringsniveaus",
    en: "Functional level",
  },
  [CITO_SCORE_FORMATS.DLE]: {
    nl: "DLE",
    en: "DLE",
  },
  [CITO_SCORE_FORMATS.REFERENCE_LEVEL]: {
    nl: "Referentieniveaus",
    en: "Reference level",
  },
  [CITO_SCORE_FORMATS.PROJECTED_REFERENCE_LEVEL]: {
    nl: "Prognose referentieniveaus",
    en: "Projected reference level",
  },
  [`${CITO_SCORE_FORMATS.LEVEL_VALUE}${SHORT_I18N}`]: {
    nl: "NW",
    en: "LW",
  },
  [`${CITO_SCORE_FORMATS.DLE}${SHORT_I18N}`]: {
    nl: "DLE",
    en: "DLE",
  },
  [`${CITO_SCORE_FORMATS.ABILITY_SCORE}${SHORT_I18N}`]: {
    nl: "VS",
    en: "AS",
  },
  [`${CITO_SCORE_FORMATS.ABILITY_SCORE}${SHORT_I18N}${TEST_PROVIDERS.IEP}`]: {
    nl: "OS",
    en: "DS",
  },
  [`${CITO_SCORE_FORMATS.FUNCTIONAL_LEVEL}${SHORT_I18N}`]: {
    nl: "FN",
    en: "FL",
  },
  [`${CITO_SCORE_FORMATS.REFERENCE_LEVEL}${SHORT_I18N}`]: {
    nl: "RN",
    en: "RL",
  },
  [`${CITO_SCORE_FORMATS.PROJECTED_REFERENCE_LEVEL}${SHORT_I18N}`]: {
    nl: "Prognose RN",
    en: "Projected RL",
  },
  [METHOD_SCORE_FORMATS.LABEL]: {
    nl: "G-V-M-O",
    en: "G-V-M-O",
  },
  [METHOD_SCORE_FORMATS.PERCENTAGE]: {
    nl: "Percentage",
    en: "Percentage",
  },
  ["method_section_result"]: {
    nl: "Resultaat",
    en: "Result",
  },

  //TODO: Move these translations to evaluations file when will start to work on it
  ["evaluations__written_evaluations"]: {
    nl: "Geschreven evaluaties",
    en: "Written evaluations",
  },
  ["max_char_limit_message"]: {
    nl: "Een evaluatie kan maximaal 5000 tekens bevatten",
    en: "An evaluation can have at most 5000 characters",
  },

  ["doorbell_handover_link_href"]: {
    nl: "https://leeruniek.nl/overdracht/",
    en: "https://leeruniek.nl/overdracht/",
  },
  ["doorbell_group_discussion_link_href"]: {
    nl: "https://leeruniek.nl/handreiking-groepsbespreking/",
    en: "https://leeruniek.nl/handreiking-groepsbespreking/",
  },
  ["doorbell_users_tutorial_href"]: {
    nl: "https://leeruniek.nl/klaar-voor-het-nieuwe-schooljaar/",
    en: "https://leeruniek.nl/klaar-voor-het-nieuwe-schooljaar/",
  },
  ["doorbell_half_yearly_analysis_link_href"]: {
    nl: "https://leeruniek.nl/halfjaarlijkse-analyse/",
    en: "https://leeruniek.nl/halfjaarlijkse-analyse/",
  },
  ["doorbell_methods_tutorial_link_href"]: {
    nl: "https://leeruniek.nl/methodetoetsen/",
    en: "https://leeruniek.nl/methodetoetsen/",
  },
  ["doorbell_pupil_notes_tutorial_href"]: {
    nl: "https://leeruniek.nl/kindkenmerken/",
    en: "https://leeruniek.nl/kindkenmerken/",
  },
  ["doorbell_education_plan_tutorial_href"]: {
    nl: "https://leeruniek.nl/onderwijsplan/",
    en: "https://leeruniek.nl/onderwijsplan/",
  },
  ["doorbell_education_plan_tutorial_text"]: {
    nl: "Onderwijsplan",
    en: "Education plan",
  },
  ["doorbell_pupil_notes_tutorial_text"]: {
    nl: "Kindkenmerken",
    en: "Annotations",
  },
  ["doorbell_multifactor_auth_app_link_href"]: {
    nl: "https://leeruniek.nl/verificatie/",
    en: "https://leeruniek.nl/verificatie/",
  },
  ["leave-unsaved-changes-notification-title"]: {
    nl: "Er is een notitie niet opgeslagen",
    en: "A note has not been saved",
  },
  ["leave-unsaved-changes-notification-message--note"]: {
    nl: "Maak deze notitie eerst af voordat je verder gaat, door deze op te slaan of te annuleren.",
    en: "Please first finish this note before moving on, by either saving or cancelling on the note.",
  },
  ["previous-year"]: {
    nl: "Vorig schooljaar",
    en: "Previous school year",
  },
  ["next-year"]: {
    nl: "Volgend schooljaar",
    en: "Next school year",
  },
  ["current-year"]: {
    nl: "Huidig schooljaar",
    en: "Current school year",
  },
  ["return-to"]: {
    nl: "Ga terug naar",
    en: "Return to",
  },
  years: {
    nl: "Jaar",
    en: "Years",
  },
  publisher: {
    nl: "Uitgever",
    en: "Publisher",
  },
  [`levelValue${SHORT_I18N}`]: {
    nl: "NW",
    en: "LW",
  },
  [`abilityScore${SHORT_I18N}`]: {
    nl: "VS",
    en: "AS",
  },
  [`functionalLevel${SHORT_I18N}`]: {
    nl: "FN",
    en: "FL",
  },
  [`referenceLevel${SHORT_I18N}`]: {
    nl: "RN",
    en: "RL",
  },
  [`projectedReferenceLevel${SHORT_I18N}`]: {
    nl: "Prognose RN",
    en: "Projected RL",
  },
  group_pupil_stimulating_factors_notes: {
    nl: "Vul op het leerlingprofiel de stimulerende factoren in.",
    en: "Fill the stimulating factors on the student profile.",
  },
  group_pupil_influencing_factors_notes: {
    nl: "Vul op het leerlingprofiel de belemmerende factoren in.",
    en: "Fill the influencing factors on the student profile.",
  },
  group_pupil_educational_needs_notes: {
    nl: "Vul op het leerlingprofiel de onderwijsbehoeften in.",
    en: "Fill the educational factors on the student profile.",
  },
  group_pupil_factor_general: {
    nl: "Leeruniek verzamelt deze voor jou op de groepspagina.",
    en: "Leeruniek collects these for you on the group page.",
  },
  group_pupil_non_system_notes__show_less: {
    nl: "Bekijk minder kindkenmerken",
    en: "Show less child characteristics",
  },
  group_pupil_non_system_notes__show_more: {
    nl: "Bekijk meer kindkenmerken",
    en: "Show more child characteristics",
  },
  all_subjects: {
    nl: "Alle vakken",
    en: "All subjects",
  },
  math_DUO: {
    nl: "Rekenen",
    en: "Math",
  },
  reading_DUO: {
    nl: "Lezen",
    en: "Reading",
  },
  language_DUO: {
    nl: "Taalverzorging",
    en: "Language",
  },
  old_data_alert_DUO: {
    nl: "Onderstaande data is afkomstig van DUO. Voor dit schooljaar heeft DUO nog geen nieuwe data vrijgegeven. Om die reden is dit onderdeel van onze schoolpagina standaard ingeklapt. Zodra er weer nieuwe data beschikbaar is, zal die hier zichtbaar zijn.",
    en: "The data below comes from DUO. DUO has not yet released any new data for this school year. For that reason, this part of our school page is collapsed by default. As soon as new data is available, it will be visible here.",
  },
  below: {
    nl: "onder",
    en: "below",
  },
  on: { nl: "op", en: "on" },
  above: {
    nl: "boven",
    en: "above",
  },
  advice: {
    nl: "Advies",
    en: "Advice",
  },
  analyze: {
    nl: "Analyse",
    en: "Analyze",
  },
  approach_note: {
    nl: "Aanpak",
    en: "Approach",
  },
  no_historic_results: {
    nl: "Geen resultaten",
    en: "No historic results",
  },
  more_information: {
    nl: "Klik hier voor meer informatie",
    en: "Click here for more information",
  },
  no_options: {
    nl: "Geen opties",
    en: "No options",
  },
  outflow_test: {
    nl: "Doorstroomtoets",
    en: "Outflow test",
  },
  outflow_test_advice: {
    nl: "Doorstroomtoetsadvies",
    en: "Outflow test advice",
  },
  mathematics_reference_level: {
    nl: "Referentieniveau Rekenen-Wiskunde",
    en: "Mathematics reference level",
  },
  language_reference_level: {
    nl: "Referentieniveau Taalverzorging",
    en: "Language reference level",
  },
  reading_reference_level: {
    nl: "Referentieniveau Lezen",
    en: "Reading reference level",
  },
  mathematics_reference_level_abbreviated: {
    nl: "RN Re",
    en: "RL Ma",
  },
  language_reference_level_abbreviated: {
    nl: "RN TV",
    en: "RL La",
  },
  reading_reference_level_abbreviated: {
    nl: "RN Le",
    en: "RL Re",
  },
}

export const t = translateWith(languagePack)
